<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="enterprises-page">
    <b-card no-body class="mb-0">
      <section>
        <b-table
          class="data-table"
          hover
          :items="organisations"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>
          <template #cell(name)="data">
            <router-link :to="{ name: 'admin-organisation-view', params: { uid: data.item.uid, organisation: data.item } }" class="font-weight-bold d-block text-nowrap">
              {{data.item.name}}
            </router-link>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreOrganisations" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p >No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
  import AdminOrganisationService from '@/services/AdminOrganisationService'

  export default {
    name: 'Organisations',
    components: {},
    props: {},
    data() {
      return {
        headers: [
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'created_at',
            label: 'Created At',
          },
          {
            key: 'actions',
            label: '',
            class: 'text-right',
          },
        ],
        organisations: [],
        currentPage: 1,
        filters: {
          page: 0,
          page_size: 25,
        },
        total_count: 0,
      };
    },
    mounted() {
      this.getOrganisations();
    },
    methods: {
      getMoreOrganisations(val) {
        this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
        this.getOrganisations();
      },
      getOrganisations() {
        AdminOrganisationService.getOrganisations(this.filters).then(res => {
          this.organisations = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(() => {
          this.$toast.error('Could not get organisations, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
    },
  };
</script>
