<template>
  <div>
    <b-card title="Organisations 🚀">
      <b-card-text>
        An organisation represents a company or grouping using the Secris system. This section is for Secris staff only.
      </b-card-text>
    </b-card>

    <organisations />

  </div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import Organisations from '@/views/organisation/Organisations.vue'

export default {
  components: {
    BCard,
    BCardText,
    Organisations,
  },
}
</script>

<style>

</style>
